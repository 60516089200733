import React, { Component } from 'react';
import './App.css';
import { Formatter } from 'swarm-numberformat';
import moment from 'moment'

var formatter = new Formatter({
  flavor: 'short'
});
console.log(formatter.format(3300000000));
// var format = formatter.format;

class App extends Component {

  constructor(props) {
    super(props);

    this.iteration = 0;

    this.partial_consts = {
      teams: {
          first: {
            name: 'Local',
            cost: {
              people: 20,
              money: 20,
              teams: 1
            },  
            prod: { money: 0.2 }
          },
          elements: ['Area', 'District', 'City',
           'Regional', 'State', 'Interstate', 'Extra League'],
          increment: {
            cost: {
              people: 2,
              money: 10,
              teams: 2
            },
            prod: { money: 5 }
          }
        },
        members: {
          first: {
            name: 'Players',
            cost: {
              money: 10,
              people: 1
            },
            prod: { people: 0.1 }
          },
          elements: ['Trainers', 'Coaches', 'Sponsors',
           'Team Owners', 'Evangelists', 'Spiritualists'],
          increment: {
            cost: {
              money: 16,
              people: 4
            },
            prod: { people: 2 }
          }
        }
    }

    this.consts =  this.getNewConsts(this.partial_consts);    
    this.state = this.getStartingState();
  }

  readState(newState, quantity) {
    if (newState[quantity] === undefined)
      return this.state[quantity];
    else
      return newState[quantity];
  }

  getNewConsts(template) {
    // teams template
    const t_t = template.teams;
    // first team
    const f_t = t_t.first;
    // increment teams
    const i_t = t_t.increment;
    // new teams
    const n_t = [f_t].concat(
      t_t.elements.map((name, index) => {
        const power = index + 1;        
        return {
          name: name,
          cost: {
            people: f_t.cost.people * Math.pow(i_t.cost.people, power),
            money: f_t.cost.money * Math.pow(i_t.cost.money, power),
            teams: f_t.cost.teams * Math.pow(i_t.cost.teams, power),
          },
          prod: { money: f_t.prod.money * Math.pow(i_t.prod.money, power) }
        };
      })
    );

    n_t.forEach((el, ix) => el.level = ix);

    // members template
    const t_m = template.members;
    // first members
    const f_m = t_m.first;
    // increment members
    const i_m = t_m.increment;
    // new members
    const n_m = [f_m].concat(
      t_m.elements.map((name, index) => {
        const power = index + 1;
        return {
          name: name,
          cost: {
            people: f_m.cost.people * Math.pow(i_m.cost.people, power),
            money: f_m.cost.money * Math.pow(i_m.cost.money, power),
          },
          prod: { people: f_m.prod.people * Math.pow(i_m.prod.people, power) }
        };
      })
    );

    n_m.forEach((el, ix) => el.level = ix);


    return {
      teams: n_t,
      members: n_m
    }
  }

  validate(newState) {
    let money = this.readState(newState, 'money');
    let people = this.readState(newState, 'people');
    let fame = this.readState(newState, 'fame');
    let teams = this.readState(newState, 'teams');
    let members = this.readState(newState, 'members');

    newState.canAddTeams = this.checkAllowedTeams(money, people, teams);
    newState.maxTeamAmounts = this.checkMaxTeams(money, people, teams);
    newState.canAddMembers = this.checkAllowedMembers(money, people);
    newState.maxMemberAmounts = this.checkMaxMembers(money, people);

    this.updateIncrements(newState);
      
    this.setState(newState);
  }

  updateIncrements(newState) {
    const teams = this.state.teams;
    const members = this.state.members;

    const sumMoney = this.consts.teams.map(team => {
      return teams[team.level] * team.prod.money;
    }).reduce((a,b) => a + b, 0);

    const sumPeople = this.consts.members.map(member => {
      return members[member.level] * member.prod.people;
    }).reduce((a,b) => a + b, 0);

    newState.increments = {
      people: 0 + sumPeople,
      money: 0 + sumMoney,
      fame: 0 + members[0],
      teams: Array(teams.length).fill(0),
      members: Array(members.length).fill(0)
    };
  }

  getStartingState() {
    const teams = this.consts.teams.length;
    const members = this.consts.members.length;
    
    return {
      // currencies:
      money: 25,
      people: 25,
      fame: 0,

      increments: {
        money: 0,
        people: 0,
        fame: 0,
        teams: Array(teams).fill(0),
        members: Array(members).fill(0)
      },

      // groups
      teams: Array(teams).fill(0),
      canAddTeams: Array(teams).fill(0),
      maxTeamAmounts: Array(teams).fill(0),

      members: Array(members).fill(0),
      canAddMembers: Array(members).fill(0),
      maxMemberAmounts: Array(members).fill(0),
    }
  }

  componentDidMount() {

    const saveStr = localStorage.getItem('unf-soccer-save');
    if (saveStr) {
      console.log('Found save, restarting', saveStr);
      try {
        const save = JSON.parse(saveStr);
        if (save.state && save.save_time) {
          console.log('Proper save found', save.save_time, save.state);
          const savedState = save.state;
          this.setState({
            money: savedState.money,
            people: savedState.people,
            fame: savedState.fame,
            // saved state can be old - and can have less teams
            teams: this.state.teams.map((t,i) => savedState.teams[i] || 0),
            // saved state can be old - and can have less members
            members: this.state.members.map((t,i) => savedState.members[i] || 0),
          });

          const oldTime = moment(save.save_time);
          console.log('time is', oldTime);
          
          // update with passed time
          const pausedMs = moment().diff(oldTime);
          console.log('pause time is', pausedMs);

          const iterations = Math.floor(pausedMs/100);

          this.updateWorldState(save.state, iterations);

          // sum up passed time
          this.iteration = Math.floor(save.passed_time + iterations);
          
        } else {
          console.warn('Wrong save format');
          this.setState(this.getStartingState());
        }
      } catch (e) {
        console.log('Save: not json');
        this.setState(this.getStartingState());  
      }
    } else {
      console.log('Did not found saves, new state');
      this.setState(this.getStartingState());
    }

    this.interval = setInterval(() => this.addFreeMoney(this.iteration++, 1), 100);

    // save before closing
    window.onbeforeunload = () => {

      // check if proper state is there
      if (this.state.money !== undefined)
      {
        const save = {
          state: this.state,
          passed_time: this.iteration,
          save_time: moment().toISOString()
        }

        localStorage.setItem('unf-soccer-save', JSON.stringify(save));
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkAllowedTeams(money, people, teams) {
    return this.consts.teams.map(team => {

      const prev_teams = teams[team.level - 1] || 0;

      return people >= team.cost.people
          && money >= team.cost.money
          && (team.level === 0 || prev_teams >= team.cost.teams)
    });
  }

  checkAllowedMembers(money, people) {
    return this.consts.members.map(member => {

      return people >= member.cost.people
          && money >= member.cost.money
    });
  }

  checkMaxTeams(money, people, teams) {
    return this.consts.teams.map(team => {

      const prev_teams = teams[team.level - 1] || 0;

      const needed = [];

      if (team.cost.people > 0)
        needed.push(Math.floor(people / team.cost.people));

      if (team.cost.money > 0)
        needed.push(Math.floor(money / team.cost.money));

      if (team.level > 0)
      {
        if (team.cost.teams > 0)
          needed.push(Math.floor(prev_teams / team.cost.teams));
      }

      let min = Math.min.apply(null, needed);
      if (min < 1)
        min = 1;

      return min;
    });
  }

  checkMaxMembers(money, people) {
    return this.consts.members.map(member => {

      const needed = [];

      if (people > 0)
        needed.push(Math.floor(people / member.cost.people));

      if (money > 0)
        needed.push(Math.floor(money / member.cost.money));

      let min = Math.min.apply(null, needed);
      if (min < 1)
        min = 1;

      return min;
    });
  }

  addFreeMoney(iter, cycles) {
    this.updateWorldState(this.state, cycles);
  }
  
  updateWorldState(state, cycles) {
    let newMoney = state.money;
    let newPeople = state.people;
    let newFame = state.fame;

    newMoney += state.increments.money * cycles;
    newPeople += state.increments.people * cycles;
    newFame += state.increments.fame * cycles;

    this.validate({
      money: newMoney,
      people: newPeople,
      fame: newFame
    });
  }

  addMoney() {

    const prevMoney = this.state.money;

    this.validate({
      money: prevMoney + 1,
    });
  }

  addPeople() {
    const newPeople = this.state.people + 1;

    this.validate({
      people: newPeople,
    });
  }

  addFame() {
    const fame = this.state.fame;
    const newFame = fame + 1;

    this.validate({
      fame: newFame,
    });
  }

  addTime() {
    this.iteration += 36000;
    this.addFreeMoney(this.iteration, 36000); // 3600s * 10/s
  }

  resetGame() {
    this.iteration = 0;
    this.setState(this.getStartingState());
  }

  addMember(level, amount) {
    const settings = this.consts.members[level];
    const newMembers = this.state.members.slice();

    newMembers[level] += amount;

    const newState = {
      members: newMembers
    };

    const costs = Object.keys(settings.cost);
    costs.forEach(costtype => {
      newState[costtype] = this.state[costtype] - amount * settings.cost[costtype];
    });

    this.validate(newState);
  }

  addTeam(level, amount) {
    const teams = this.state.teams;
    const settings = this.consts.teams[level];

    const costs = Object.keys(settings.cost).filter(e => e !== 'teams');

    const newTeams = teams.slice();

    const team_cost = this.consts.teams[level].cost.teams;

    // there is no "-1" level
    if (level > 0)
      newTeams[level - 1] = newTeams[level - 1] - amount * team_cost;

    newTeams[level] = newTeams[level] + amount;

    const newState = {
      teams: newTeams
    };

    costs.forEach(costtype => {
      newState[costtype] = this.state[costtype] - amount * settings.cost[costtype];
    });

    this.validate(newState);
  }  

  render() {

    const money = this.state.money.toFixed(2);
    const people = this.state.people;
    const all_teams = this.state.teams;
    const all_members = this.state.members;
    const fame = this.state.fame;
    const time = moment.duration(this.iteration * 100).humanize();

    const max_teams = this.state.teams.map(a => !!a).lastIndexOf(true) + 2;

    // don't show members until...
    let max_members = 0;
    if (max_teams > 1)    
      max_members = this.state.members.map(a => !!a).lastIndexOf(true) + 2;

    const disableTeam = this.state.canAddTeams.map(i => !i);
    const disableMembers = this.state.canAddMembers.map(i => !i);

    const team_amounts = this.state.maxTeamAmounts;
    const member_amounts = this.state.maxMemberAmounts;

    const show = function(number) {
      return formatter.format(number);
    }

    const t_settings = this.consts.teams.slice(0, max_teams);
    const teams = all_teams.slice(0, max_teams);

    const m_settings = this.consts.members.slice(0, max_members);
    const members = all_members.slice(0, max_members);

    const team_names = t_settings.map(team =>
      <td>{team.name}</td>
    );

    const team_points = teams.map(team => 
      <td>{show(team)}</td>
    );

    const team_buttons = t_settings.map(team =>
      <td>
      <button 
        onClick={i => this.addTeam(team.level, 1)}
        disabled={disableTeam[team.level]}
      >+1</button>
    </td> 
    );

    const team_buttons_all = t_settings.map(team => {
      const level = team.level;
      const amount = team_amounts[level]

      return (
      <td>
      <button 
        onClick={i => this.addTeam(team.level, amount)}
        disabled={disableTeam[team.level]}
      >+{show(amount)}</button>
    </td> 
    )});


    const member_buttons = m_settings.map(member =>
      <td>
      <button 
        onClick={i => this.addMember(member.level, 1)}
        disabled={disableMembers[member.level]}
      >+1</button>
    </td> 
    );

    const member_buttons_all = m_settings.map(member => {
      const level = member.level;
      const amount = member_amounts[level]

      return (
      <td>
        <button 
          onClick={i => this.addMember(member.level, amount)}
          disabled={disableMembers[member.level]}
        >+{show(amount)}</button>
      </td> 
    )});

    const member_names = m_settings.map(member =>
      <td>{member.name}</td>
    );

    const member_points = members.map(member => 
      <td>{show(member)}</td>
    );

    return (
      <div className="App App-header">
        <table>
          <thead>
            <tr>
              <td>Money</td>
              <td>People</td>
              <td>Fame</td>
              <td>Time</td>
            </tr>
          </thead>
            <tbody>
              <tr>
                <td>{show(money)}$</td>
                <td>{show(people)}</td>
                <td>{show(fame)}</td>
                <td>{time}</td>
              </tr>
              <tr>
                <td><button onClick={i => this.addMoney()}>+1</button></td>
                <td><button onClick={i => this.addPeople()}>+1</button></td>
                <td><button onClick={i => this.addFame()}>+1</button></td>
                <td><button title="Add 1 hour" onClick={i => this.addTime()}>+∞</button></td>
                <td><button title="Restart game" onClick={i => this.resetGame()}>✖</button></td>
              </tr>
            </tbody>
          </table>
          <table>
            <caption>Teams</caption>
            <thead>
              <tr>
                {team_names}
              </tr>
            </thead>
            <tbody>
              <tr>
                {team_points}
              </tr>
              <tr>
                {team_buttons}
              </tr>
              <tr>
                {team_buttons_all}
              </tr>
            </tbody>
          </table>
          <table>
            <caption>Team Members</caption>
            <thead>
              <tr>
                {member_names}
              </tr>
            </thead>
            <tbody>
              <tr>
                {member_points}
              </tr>
              <tr>
                {member_buttons}
              </tr>
              <tr>
                {member_buttons_all}
              </tr>
            </tbody>
          </table>
      </div>
    );
  }
}

export default App;
